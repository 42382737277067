<template>
    <div>
        <a-button @click="showMapAlert" type="primary" ghost>{{addrName==''?'选择':'重新选择'}}</a-button>
        <a-modal
            width="800px"
            title="选择坐标"
            v-model="showMap"
             @ok="selectAct"
             okText="确认"
             cancelText="取消"
            >

        <a-form-item  label="关键词" :label-col="{ span: 5 }" :wrapper-col="{ span: 15 }">
            <a-input v-model="keyword"/>
        </a-form-item>

        <baidu-map class="baidu-map" :center="mapConfig.center" :zoom="mapConfig.zoom" @ready="mapHandler">
            <bm-city-list anchor="BMAP_ANCHOR_TOP_LEFT"></bm-city-list>
            <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true" :autoLocation="true"></bm-geolocation>
            <bm-local-search  :keyword="keyword" :auto-viewport="true" :forceLocal="true" :panel="false"></bm-local-search>
            <bm-marker v-if="select.lat && select.lng && isDefault == false" :position="{lng: select.lng, lat: select.lat}"></bm-marker>
            <bm-marker v-if="lat && lng && isDefault == true" :position="{lng: lng, lat: lat}"></bm-marker>
        </baidu-map>
        </a-modal>
    </div>
</template>
<script>
import Vue from 'vue'
import BaiduMap from 'vue-baidu-map';
Vue.use(BaiduMap, {
  ak: 'plSNfkDGpi5VqyX9TCHwhVQymAkFLVD0'
});
export default {
    props:{
        lng:'',
        lat:'',
		addrName:'',//仅用于判断按钮显示的文字
    },
    computed:{
        mapConfig(){
            return {
				center: { lng: this.lng ? this.lng : 116.394007, lat: this.lat ? this.lat : 39.907471 },
                zoom: 15,
                show: true,
                dragging: true
            }
        }
    },
    data(){
        return {
            showMap:false,
            keyword:'',
            select:{
                lat:'',
                lng:''
            },
            isDefault:true,
        }
    },
    methods:{
        showMapAlert(){
            this.showMap = true;
        },
        selectAct(){
             this.showMap = false;
             this.$emit('select',this.select);
        },
        mapHandler({ BMap, map }) {
        	let me = this;
        	    map.enableScrollWheelZoom(true);
        	    // 创建一个地理编码对象
        	    let geocoder = new BMap.Geocoder();
        	
        	    // 点击事件获取经纬度
        	    map.addEventListener('click', function (e) {
        	        me.isDefault = false;
        	        me.select = {
        	            lat: e.point.lat,
        	            lng: e.point.lng
        	        }
        	
        	        // 通过点击的经纬度获取地址信息
        	        geocoder.getLocation(e.point, function(result) {
        	            if (result) {
        	                console.log("点击点的地址名称：", result.address);
        	                // 这里可以将地址赋值给某个变量或者用于其他操作
        	                me.select.address = result.address;
        	            } else {
        	                console.log("无法获取地址");
        	            }
        	        });
        	    })
        }
    }
}
</script>
<style>
.baidu-map{
    width: 100%;
    height: 400px;
}
</style>