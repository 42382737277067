<template>
	<div>
		<div class="bg-w pd16_15 cl-balck">
			<div class="ft20 cl-main ftw600">附近课程</div>
			<div class="flex alcenter mt20">
				<!-- <div class="ft14 ftw600" >附近课程</div> -->
				<div class="mr15">
					位置：{{form.address}}
				</div>
				<baidu-map :lat="form.lat" :lng="form.lng" @select="selectAct" :addrName="form.address"></baidu-map>
				
				<div class="flex alcenter ml30 mr15" >
					课程关键字：
					<a-input v-model="search.key_word" style="width: 150px;" placeholder="请输入" @pressEnter="searchAct"></a-input>
				</div>
				
				<a-button @click="searchAct" type="primary">搜索</a-button>
				<a-button @click="cancelAct" class="ml10">取消</a-button>
			</div>
			<div class="mt20" v-if="form.address">
				<div class="ft14 ftw600">
					您好，以下是{{form.address}}附近课程：
				</div>
				<div class="mt20">
					<a-table :scroll="{x:1200}" rowKey="group_number_id" :columns="columns" :pagination="false"
						@change="handleTableChange" :data-source="datas" :loading="loading">
						<template slot="action" slot-scope="record">
							<div class="flex center">
								<a class="menu-act" href="javascript:;" @click="toCopy(record)">
									<span class="ml10">一键复制</span>
								</a>
								<a class="menu-act" href="javascript:;" @click="toLine(record)">
									<span class="ml10">查看路线</span>
								</a>
							</div>
						</template>
					</a-table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import BaiduMap from '../components/map/baidumap.vue'
	export default{
		components:{
			BaiduMap
		},
		data(){
			return{
				search:{
					key_word:''
				},
				columns:[
					{
						title: '课程名称',
						dataIndex: 'name',
						width:480
					},
					{
						title: '地址',
						dataIndex: 'address',
					},
					{
						title: '距离',
						dataIndex: 'km',
					},
					{
						title: '驾车时长',
						dataIndex: 'drive',
					},
					{
						title: '公交时长',
						dataIndex: 'bus',
					},
					{
						title: '操作',
						key: 'action',
						align: 'center',
						scopedSlots: {
							customRender: 'action'
						}
					}
				],
				datas:[],
				pagination:{
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				loading:false,
				form:{
					lat:'',
					lng:'',
					address:''
				}
			}
		},
		methods:{
			toCopy(record){
				const textarea = document.createElement('textarea');
				textarea.value = `宝子，我查询了一下，${record.name}自驾 ${record.drive}，公共交通 ${record.bus}`;
				document.body.appendChild(textarea);
				textarea.select(); // 选择文本
				document.execCommand('copy'); // 执行复制
				document.body.removeChild(textarea); // 移除临时创建的textarea
				this.$message.success('复制成功')
			},
			selectAct(e) {
				this.form.lat = e.lat;
				this.form.lng = e.lng;
				this.form.address = e.address
				// this.getNearCourses()
			},
			toLine(e){
				let endAddress = e.nav_address?e.nav_address:e.address
				// http://api.map.baidu.com/direction?origin=latlng:latitude1,longitude1|name:起点名称&destination=latlng:latitude2,longitude2|name:终点名称&mode=driving&region=全国&output=html&src=yourCompanyName|yourAppName
				let url = `http://api.map.baidu.com/direction?origin=latlng:${this.form.lat},${this.form.lng}|name:${this.form.address}&destination=latlng:${e.lat},${e.lng}|name:${endAddress}&mode=driving&region=全国&output=html&src=MyCompany|MyApp`
				window.open(url, '_blank');
			},
			searchAct(){
				this.getNearCourses()
			},
			cancelAct(){
				this.form={
				 	lat:'',
				 	lng:'',
				 	address:''
				 }
				this.search.key_word = ''
			},
			getNearCourses(){
				if(this.loading) return
				this.loading = true
				this.$http.api('platform/account/getNearCourses',{
					keyword:this.search.key_word,
					lng:this.form.lng,
					lat:this.form.lat
				}).then(res=>{
					this.loading = false
					this.datas = res.goods
				})
			}
		}
	}
</script>

<style>
</style>